import React, { Component } from 'react';
import './App.css';
import RgbTextDivider from './components/RgbTextDivider/RgbTextDivider';

class App extends Component {
  constructor(props){
    super(props)
    if (window.location.host !== 'www.wilhelmraab.se' && window.location.host !== 'wilhelmraab.se'){
      document.title = '[DEV] ' + document.title
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="crt">
            <RgbTextDivider text="wilhelmraab.se" />
          </div>
        </header>
      </div>
    );
  }
}

export default App;
