import React, { Component } from 'react';
import './RgbTextDivider.css';

class RgbTextDivider extends Component {
    constructor(props) {
        super(props);
        this.state = { text: this.props.text };
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="rgbtextdivider">
              <div className="r">{this.state.text}</div>
              <div className="g">{this.state.text}</div>
              <div className="b">{this.state.text}</div>
            </div>
        );
    }
}

export default RgbTextDivider;